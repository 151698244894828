import React, { useState } from 'react';

const FAQ = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  const handleAccordionToggle = (index) => {
    return activeAccordion === index ? 'max-h-full' : 'max-h-0';
  };

  const handleRotateIcon = (index) => {
    return activeAccordion === index ? 'rotate-180' : 'rotate-0';
  };

  return (
    <section id='FAQ' className="p-5 bg-light-blue m-5">
      <div className="flex justify-center items-start my-2">
        <div className="w-full sm:w-10/12 md:w-1/2 my-1">
          <h2 className="text-center text-4xl font-semibold text-[#d4af37] m-4">FAQ</h2>
          <ul className="flex flex-col">
            <li className="bg-white my-2 shadow-lg">
              <h2
                onClick={() => handleAccordionClick(1)}
                className="flex flex-row justify-between items-center font-semibold p-4 cursor-pointer text-xl"
              >
                <span>Quels types de téléphones réparez-vous ?</span>
                <svg
                  className={`fill-current text-[#d4af37] h-6 w-6 transform transition-transform duration-500 ${handleRotateIcon(1)}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </h2>
              <div
                className={`border-l-2 border-[#d4af37] overflow-hidden duration-500 transition-all ${handleAccordionToggle(1)}`}
              >
                <p className="p-3 text-gray-900">
                Nous réparons une large gamme de téléphones, y compris les modèles iPhone, Samsung, Google, et bien d'autres.
                </p>
              </div>
            </li>
            
            <li className="bg-white my-2 shadow-lg">
              <h2
                onClick={() => handleAccordionClick(2)}
                className="flex flex-row justify-between items-center font-semibold p-4 cursor-pointer text-xl"
              >
                <span>Quels types de réparations proposez-vous ?</span>
                <svg
                  className={`fill-current text-[#d4af37] h-6 w-6 transform transition-transform duration-500 ${handleRotateIcon(2)}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </h2>
              <div
                className={`border-l-2 border-[#d4af37] overflow-hidden duration-500 transition-all ${handleAccordionToggle(2)}`}
              >
                <p className="p-3 text-gray-900">
                Nous offrons des services de réparation d'écran, de remplacement de batterie, de réparation de caméra, de réparation de connecteur de charge, et bien plus encore.
                </p>
              </div>
            </li>
            
            <li className="bg-white my-2 shadow-lg">
              <h2
                onClick={() => handleAccordionClick(3)}
                className="flex flex-row justify-between items-center font-semibold p-4 cursor-pointer text-xl"
              >
                <span>Quelle est la durée moyenne d'une réparation de téléphone ?</span>
                <svg
                  className={`fill-current text-[#d4af37] h-6 w-6 transform transition-transform duration-500 ${handleRotateIcon(3)}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </h2>
              <div
                className={`border-l-2 border-[#d4af37] overflow-hidden duration-500 transition-all ${handleAccordionToggle(3)}`}
              >
                <p className="p-3 text-gray-900">
                La durée d'une réparation varie en fonction du type de problème et du modèle de téléphone, mais la plupart des réparations peuvent être effectuées en moins d'une heure.
                </p>
              </div>
            </li>
            <li className="bg-white my-2 shadow-lg">
              <h2
                onClick={() => handleAccordionClick(4)}
                className="flex flex-row justify-between items-center font-semibold p-4 cursor-pointer text-xl"
              >
                <span>Fournissez-vous des pièces de rechange authentiques ?</span>
                <svg
                  className={`fill-current text-[#d4af37] h-6 w-6 transform transition-transform duration-500 ${handleRotateIcon(4)}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </h2>
              <div
                className={`border-l-2 border-[#d4af37] overflow-hidden duration-500 transition-all ${handleAccordionToggle(4)}`}
              >
                <p className="p-3 text-gray-900">
                Oui, nous utilisons des pièces de rechange de haute qualité, y compris des pièces authentiques lorsque cela est possible, pour garantir la meilleure qualité de réparation.
                </p>
              </div>
            </li>
            <li className="bg-white my-2 shadow-lg">
              <h2
                onClick={() => handleAccordionClick(5)}
                className="flex flex-row justify-between items-center font-semibold p-4 cursor-pointer text-xl"
              >
                <span>Puis-je obtenir un devis avant la réparation ?</span>
                <svg
                  className={`fill-current text-[#d4af37] h-6 w-6 transform transition-transform duration-500 ${handleRotateIcon(5)}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </h2>
              <div
                className={`border-l-2 border-[#d4af37] overflow-hidden duration-500 transition-all ${handleAccordionToggle(5)}`}
              >
                <p className="p-3 text-gray-900">
                Oui, nous vous fournirons un devis précis avant de commencer toute réparation, afin que vous sachiez exactement combien cela coûtera il suffit de nous contacter En nous appelant ou bien sur Whatsapp/Facebook/Email/Snapchat ou juste en remplissant le formulaire en bas de la page.
                </p>
              </div>
            </li>
            <li className="bg-white my-2 shadow-lg">
              <h2
                onClick={() => handleAccordionClick(6)}
                className="flex flex-row justify-between items-center font-semibold p-4 cursor-pointer text-xl"
              >
                <span>Proposez-vous la vente de pièces détachées pour les téléphones ?</span>
                <svg
                  className={`fill-current text-[#d4af37] h-6 w-6 transform transition-transform duration-500 ${handleRotateIcon(6)}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </h2>
              <div
                className={`border-l-2 border-[#d4af37] overflow-hidden duration-500 transition-all ${handleAccordionToggle(6)}`}
              >
                <p className="p-3 text-gray-900">
                Oui, nous proposons également la vente de pièces détachées pour les téléphones sur demande. Si vous avez besoin d'une pièce spécifique pour votre téléphone, n'hésitez pas à nous contacter pour vérifier notre disponibilité. Nous serons heureux de vous fournir la pièce nécessaire pour votre réparation ou pour vos besoins personnels.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
