import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import ImgBackground from '../Imgs/carteJimmy.jpg';

function ContactForm() {
  const [state, handleSubmit] = useForm("xjvqrqln");

  if (state.succeeded) {
    return (
      <div className="flex justify-center items-center min-h-[200px] bg-[#d4af37]">
        <p className="text-3xl text-white font-bold">
          Thank you for choosing Rouxy Phone ! We received your request and will get in touch with you soon.
        </p>
      </div>
    );
  }

  return (
    <section id='Contact' className="relative flex justify-center items-center min-h-screen bg-[#d4af37]">
    <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${ImgBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.6)', // Ajouter un filtre sombre
        }}
      />
    <form onSubmit={handleSubmit} className="z-10 w-full md:w-1/2 m-10 p-10 bg-white rounded shadow">
        <div className="mb-8 text-3xl font-bold text-[#d4af37] text-center">Contactez nous</div>
        <div>
          <label htmlFor="firstName" className="block font-semibold">Prénom :</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            required
            className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:border-[#d4af37]"
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block font-semibold">Nom de famille :</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            required
            className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:border-[#d4af37]"
          />
        </div>
        <div>
          <label htmlFor="description" className="block font-semibold">Votre message :</label>
          <input
            type="text"
            id="description"
            name="description"
            required
            className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:border-[#d4af37]"
          />
        </div>
        <div>
          <label htmlFor="email" className="block font-semibold">Adresse mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:border-[#d4af37]"
          />
        </div>
        <div>
          <label htmlFor="phoneNumber" className="block font-semibold">Numéro de téléphone (optionnel):</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:border-[#d4af37]"
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 mt-10 text-white bg-[#d4af37] rounded-lg hover:bg-yellow-700"
          disabled={state.submitting}
        >
          Envoyer
        </button>
        <ValidationError
          prefix="Error"
          errors={state.errors}
        />
      </form>
    </section>
  );
}

export default ContactForm;
