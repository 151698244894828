import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import images from "../Imgs/images.js";

function Resultats() {
    return (
      <section id="Resultats" className="bg-black">
        <div className="container mx-auto py-12">
          <Carousel
            showArrows={true}
            showThumbs={true}
            dynamicHeight={true}
            emulateTouch={true}
            centerMode={true} // Pour centrer l'image actuelle
            centerSlidePercentage={100} // Pour afficher l'image actuelle en plein écran
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Travail ${index + 1}`}
                  style={{ maxWidth: "75%", maxHeight: "500px" }} // Ajustez ces valeurs selon vos besoins
                />
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    );
  }
  
  export default Resultats;
  