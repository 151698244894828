import React from 'react';
import { FaInstagram, FaTiktok, FaFacebook, FaWhatsapp, FaSnapchat } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';

const Footer = () => {
  return (
    <footer className="bg-black py-4">
      <div className="container mx-auto flex items-center justify-between text-white">
        <div>
          <p className="text-sm">© RouxyPhone 2023. All rights reserved.</p>
        </div>
        <div className="flex items-center space-x-4">
          <a href="https://www.instagram.com/rouxy_phone/?igshid=NGVhN2U2NjQ0Yg" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-white text-xl" />
          </a>
          <a href="https://www.facebook.com/Rouxyphone" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-white text-xl" />
          </a>
          <a href="https://wa.me/+33771776073" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="text-white text-xl" />
          </a>
          <a href="https://t.snapchat.com/zpRrqqIL" target='_blank' rel="noopener noreferrer">
            <FaSnapchat className="text-white text-xl" />
          </a>
          <a href="https://www.tiktok.com/@rouxyphone" target="_blank" rel="noopener noreferrer" >
            <FaTiktok className="text-white text-xl" />
          </a>
          <a href="rouxy.phone@gmail.com">
            <MdMail className="text-white text-xl" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
