import { useState } from 'react';
import logo from '../Imgs/logo.png';
import { Link } from 'react-scroll';

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const activeStyle = {
    color: '#d4af37',
    backgroundColor: 'transparent',
  };

  return (
    <nav className="bg-white bg-opacity-30 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
        <a href="/" className="flex items-center">
          <img src={logo} className="h-16 my-2 mx-2" alt="Rouxy Logo" />
        </a>
        <div className="flex md:order-2">
            <Link
                to="Contact"
                spy={true}
                smooth={true}
                duration={500}
                className={`cursor-pointer text-white bg-[#d4af37] hover:bg-[#d4af37] focus:ring-4 focus:outline-none focus:ring-[#d4af37] font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 hidden md:inline-block`}
                onClick={() => setActiveLink('Contact')}
              >
                Contact
              </Link>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center mx-3 my-3 p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen ? 'true' : 'false'}
          >
            <span className="sr-only"></span>
            <svg
              className="w-7 h-7"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
            isMenuOpen ? 'block' : 'hidden'
          }`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-bold border border-gray-100 rounded-lg bg-white bg-opacity-70 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent md:relative">
            <li>
              <Link
                to="Home"
                spy={true}
                smooth={true}
                duration={500}
                className={`cursor-pointer block py-2 pl-3 pr-4 text-black rounded hover:bg-[#d4af37] hover:text-[#d4af37] md:hover:bg-transparent md:hover:text-[#d4af37] md:p-0 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ${activeLink === 'Home' ? 'active' : ''}`}
                onClick={() => setActiveLink('Home')}
                style={activeLink === 'Home' ? activeStyle : null}
              >
                Acceuil
              </Link>
            </li>
            <li>
              <Link
                to="About"
                spy={true}
                smooth={true}
                duration={500}
                className={`cursor-pointer block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-[#d4af37] hover:text-[#d4af37] md:hover:bg-transparent md:hover:text-[#d4af37] md:p-0 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ${activeLink === 'About' ? 'active' : ''}`}
                onClick={() => setActiveLink('About')}
                style={activeLink === 'About' ? activeStyle : null}
              >
                À propos
              </Link>
            </li>
            <li>
              <Link
                to="Resultats"
                spy={true}
                smooth={true}
                duration={500}
                className={`cursor-pointer block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-[#d4af37] hover:text-[#d4af37] md:hover:bg-transparent md:hover:text-[#d4af37] md:p-0 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ${activeLink === 'About' ? 'active' : ''}`}
                onClick={() => setActiveLink('Resultats')}
                style={activeLink === 'Resultats' ? activeStyle : null}
              >
                Résultats
              </Link>
            </li>
            <li>
              <Link
                to="FAQ"
                spy={true}
                smooth={true}
                duration={500}
                className={`cursor-pointer block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-[#d4af37] hover:text-[#d4af37] md:hover:bg-transparent md:hover:text-[#d4af37] md:p-0 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ${activeLink === 'FAQ' ? 'active' : ''}`}
                onClick={() => setActiveLink('FAQ')}
                style={activeLink === 'FAQ' ? activeStyle : null}
              >
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
