import React from "react";
import Img from '../Imgs/background-2.jpg';

function About() {
    return (
      <section id="About" className="bg-[#FFFBF5]">
        <div className="container mx-auto py-12 px-4">
          <div className="flex flex-col md:flex-row gap-20">
            <div className="w-full md:w-1/2">
            <h1 className="text-4xl font-bold text-[#d4af37] mb-8 text-center">
                À Propos de Nous
              </h1>
              <p className="text-center text-lg text-gray-700 leading-loose">
                Chez Rouxy Phone, nous sommes passionnés par la réparation de téléphones mobiles. Notre mission est de vous offrir les meilleurs services de réparation pour votre téléphone, avec l'expertise d'un professionnel dédié.
              </p>
              <p className="text-center text-lg text-gray-700 leading-loose mt-4">
                Notre expert en réparation de téléphone comprend les défis et les opportunités liés à la gestion des problèmes de téléphone. Nous proposons une gamme de services adaptés à vos besoins spécifiques, notamment la réparation rapide et efficace de tous les types de téléphones.
              </p>
              <p className="text-center text-lg text-gray-700 leading-loose mt-4">
                Nous nous engageons à fournir des résultats exceptionnels et à établir des relations à long terme avec nos clients. Nous croyons en la transparence, l'intégrité et la confidentialité, garantissant que vos informations personnelles et professionnelles sont toujours en sécurité.
              </p>
              <p className="text-center text-lg text-gray-700 leading-loose mt-4">
                Prêt à résoudre vos problèmes de téléphone? Contactez Rouxy Phone dès aujourd'hui et laissez notre expert vous aider à retrouver un téléphone en parfait état. Nous sommes impatients de vous accompagner dans ce processus!
              </p>
            </div>
            <div className="hidden lg:flex lg:w-1/2 justify-center mt-8 md:mt-0">
              <img
                className="mt-20 ml-5 rounded-lg shadow max-h-[calc(100vh - 200px)]"
                src={Img}
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  export default About;
  