import backgroundImg from '../Imgs/jimmyReparation.jpeg';
import { Link } from 'react-scroll';

function Hero() {
  return (
    <section id='Home' className="relative">
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.5)', // Ajouter un filtre sombre
        }}
      />
      <div className="flex flex-col items-center justify-center h-screen text-white text-center relative">
        <h1 className="text-5xl font-bold my-8 mb-8 max-w-lg">
          Bienvenue chez <span className='text-[#d4af37]'>Rouxy Phone</span>
        </h1>
        <p className="text-2xl mb-8 max-w-2xl">
         Votre expert en réparation de téléphone, notre équipe d'experts vous offre des services de réparation de téléphone de haute qualité. Nous réparons rapidement et efficacement tous les types de téléphones. <br></br>Contactez-nous dès aujourd'hui pour remettre votre téléphone en état!
        </p>
        <Link to="Contact" 
              className="cursor-pointer hover:text-white bg-[#d4af37] hover:bg-[#d4af37] text-black rounded-lg px-6 py-3 font-medium">
          Prendre rendez-vous
        </Link>
      </div>
    </section>
  );
}

export default Hero;

