import image1 from "./IMG_0231.jpg";
import image2 from "./IMG_0232.jpg";
import image3 from "./IMG_0233.jpg";
import image4 from "./IMG_0301.jpg";
import image5 from "./IMG_0322.jpg";
import image6 from "./IMG_0323.jpg";
import image7 from "./IMG_0369.jpg";
import image8 from "./IMG_0373.jpg";
import image9 from "./IMG_3057.jpg";
import image10 from "./IMG_3067.jpg";
import image11 from "./IMG_3081.jpg";
import image12 from "./IMG_3146.jpg";
import image13 from "./IMG_3144.jpg";
import image14 from "./IMG_3145.jpg";
// import image15 from "./IMG_3533.png";
import image16 from "./IMG_3656.jpg";
import image17 from "./IMG_3655.jpg";
import image18 from "./IMG_0405.jpg";
import image19 from "./IMG_0406.jpg";
import image20 from "./IMG_0451.jpg";
import image21 from "./IMG_0453.jpg";
import image22 from "./IMG_0454.jpg";
import image23 from "./IMG_0645.jpg";
import image24 from "./IMG_0648.jpg";
import image25 from "./IMG_3550.jpg";
// import image26 from "./IMG_3533.png";
import image27 from "./IMG_3551.jpg";
import image28 from "./IMG_3602.jpg";
import image29 from "./IMG_2043.jpg";
import image30 from "./IMG_2044.jpg";
import image31 from "./IMG_2045.jpg";
import image32 from "./IMG_2636.jpg";
import image33 from "./IMG_2676.jpg";

const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    // image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    // image26,
    image27,
    image28,
    image29,
    image30,
    image31,
    image32,
    image33,
];

export default images;
