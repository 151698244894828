import Nav from './Assets/NavBar';
import Hero from './Assets/Hero';
import About from './Components/About';
import Resultats from './Components/Resultats';
import FAQ from './Components/FAQ';
import Contact from './Components/Contact';
import Footer from './Assets/Footer';

function App() {
  return (
    <>
    <Nav />
    <Hero />
    <About />
    <Resultats />
    <FAQ />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
